import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/oprogramowanie-dla-firm-zmiana-w-organizacji-.jpg";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    crm1: file(
      relativePath: { eq: "proces-zarzadzania-zmiana-w-organizacji.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Nowasprzedaz = ({ data }) => {
  return (
    <Artykul
      title="Skuteczne wdrożenie CRM, czyli jak zarządzić zmianą w organizacji w “Nowej Sprzedaży” "
      keywords={["Skuteczne wdrożenie CRM w Nowej Sprzedaży"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Co tak naprawdę ogranicza Twoją sprzedaż? CEO Questy w “Nowej Sprzedaży”"
      metaTitle="Skuteczne wdrożenie CRM w “Nowej Sprzedaży”"
      metaDescription="Skuteczne wdrożenie CRM w “Nowej Sprzedaży” ✅ Szanse i zagrożenia wdrożenia systemu CRM • Minimalizacja ryzyka"
    >
      <br />
      <br />
      <p>
        Tematem numeru w najnowszym wydaniu (maj – czerwiec 2019) czasopisma
        “Nowa Sprzedaż” jest skuteczne wdrożenie CRM. Na łamach swojego
        artykułu, Łukasz Tadyszak — CEO Questy{" "}
        <strong>
          wyjaśnia, jakie szanse i zagrożenia niesie za sobą wdrażanie rozwiązań
          CRM
        </strong>
        . Pokazuje sposób, w jaki należy zarządzać tego typu projektami tak, aby
        minimalizować ryzyko i osiągnąć realne korzyści biznesowe.
      </p>
      <br />
      <br />
      <h4>
        <strong> Czego dowiesz się z artykułu? </strong>{" "}
      </h4>
      <p>
        <ul>
          <li> Jak zarządzać zmianą w nowoczesnej organizacji? </li>
          <li> Co stanowi realny sukces wdrożenia rozwiązań biznesowych? </li>
          <li> Jakie są kluczowe etapy wdrożenia CRM? </li>
          <li> Jaka jest rola kierownika projektu? </li>
          <li>
            {" "}
            Jaka jest rola mierzenia i doskonalenia procesów biznesowych?{" "}
          </li>
        </ul>
      </p>
      <br />
      <br />
      <center>
        {" "}
        <h3> Jak wygląda proces zarządzania zmianą w organizacji? </h3>{" "}
      </center>
      <br />
      <GatsbyImage
        image={data.crm1.childImageSharp.gatsbyImageData}
        style={{
          width: "0 auto",
          margin: "0 auto",
          maxWidth: 700,
        }}
        title="Skuteczne wdrożenie CRM"
        alt="skuteczne wdrożenie crm"
      />
      <br />
      <br />
      <p>
        Cały artykuł znajdziecie pod tym{" "}
        <strong>
          <a href="https://www.nowa-sprzedaz.pl/artykul/skuteczne-wdrozenie-crm-czyli-jak-zarzadzic-zmiana-w-organizacji/">
            linkiem
          </a>
        </strong>
        . Zapraszamy do lektury!{" "}
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie wypróbować system CRM w Twojej firmie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i wejść na wyższy poziom sprzedaży
            </Link>
          </div>
          </div>
    </section>

    </Artykul>
  );
};

export default Nowasprzedaz;
